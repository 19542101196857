/* eslint-disable array-callback-return, no-param-reassign */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import { Tabs } from './Tabs/Tabs';
import { Header } from './Header/Header';
import { BWIList } from './List/List';
// import './MultiTabList.scss';

export const MultiTabList = props => {
  // Save the original data to an unchangeable variable, and calculate the ranks based on array position.
  const data = props.data.map(list => {
    if (list.type === 'company_list') {
      list.data.map((row, i) => {
        row.rank = i + 1;
      });

      return list;
    }
    return list;
  });

  const [column, setColumn] = useState(0);
  const [listData, setListData] = useState(data);
  const [sortDesc, setSortDesc] = useState(false);

  const scrollHere = useRef();

  const sortData = (i = 0, flip) => {
    const revSort = flip ? !sortDesc : false; // State doesn't update in time for it to be read, so do this instead.

    const criteria = props.headers[props.activeTab][i].name;
    const results = revSort
      ? sortBy(data[props.activeTab].data, [o => o[criteria]]).reverse()
      : sortBy(data[props.activeTab].data, [o => o[criteria]]);

    setListData(results);
    setSortDesc(revSort);
  };

  // Update the data based on selected tab.
  useEffect(
    () => {
      setListData(data[props.activeTab].data);
      props.setActiveTab(props.activeTab);
    },
    [props.activeTab]
  );

  return (
    <div className={`multi-tab-list ${props.list}`} ref={scrollHere}>
      {(!props.isMobile || props.overrideMobile) && (
        <>
          {props.showFixed && (
            <div className="tab-fixed">
              <Tabs
                tab={props.activeTab}
                setTab={props.setActiveTab}
                showShareTabIcon
                {...props}
              />
            </div>
          )}
          <Tabs tab={props.activeTab} setTab={props.setActiveTab} {...props} />
        </>
      )}

      <Header
        tab={props.activeTab}
        column={column}
        setColumn={setColumn}
        sortData={sortData}
        sortDesc={sortDesc}
        isMobile={props.isMobile}
        isTablet={props.isTablet}
        {...props}
      />

      <BWIList
        data={listData}
        tab={props.activeTab}
        isMobile={props.isMobile}
        isTablet={props.isTablet}
        iconStyle={props.iconStyle}
        year={props.year}
        enableShare={props.enableShare}
        enableHiringColumn={props.enableHiringColumn}
        params={props.params}
      />
    </div>
  );
};

MultiTabList.propTypes = {
  data: PropTypes.shape({
    map: PropTypes.func
  }).isRequired,
  headers: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))).isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeTab: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  overrideMobile: PropTypes.bool,
  isTablet: PropTypes.bool,
  iconStyle: PropTypes.string,
  setActiveTab: PropTypes.func.isRequired,
  params: PropTypes.shape({}),
  year: PropTypes.string,
  enableShare: PropTypes.bool,
  enableHiringColumn: PropTypes.bool,
  showFixed: PropTypes.bool,
  shareUri: PropTypes.string
};

MultiTabList.defaultProps = {
  year: '',
  params: {},
  enableShare: false,
  enableHiringColumn: false,
  isTablet: false,
  overrideMobile: false,
  iconStyle: '',
  showFixed: false,
  shareUri: ''
};
