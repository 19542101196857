import React from 'react';
import PropTypes from 'prop-types';

const Pill = props => (
  <div className={`pill ${props.variant} list-${props.year}`}>
    <p className="pill-text">{props.text}</p>
  </div>
);

Pill.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
  year: PropTypes.string
};

Pill.defaultProps = {
  variant: 'yellow',
  year: '2024'
};

export default Pill;
