/**
 * Take an Airtable flavor of markdown and convert it to html
 * @param {string} markdown the markdown string to convert to html
 * @returns {string} the converted html string
 */
const airtableMarkdownToHtml = markdown => {
  // Escape on non string data, return the original data
  if (typeof markdown !== 'string') return markdown;

  let useMarkdown = markdown;
  // Replace bold (**text**) with <strong>text</strong>
  useMarkdown = useMarkdown.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

  // Replace italics (_text_) with <em>text</em>
  useMarkdown = useMarkdown.replace(/_(.*?)_/g, '<em>$1</em>');

  // Replace hyperlinks [text](url) with <a href="url">text</a>
  useMarkdown = useMarkdown.replace(
    /\[([^\]]+)]\(([^)]+)\)/g,
    '<a href="$2">$1</a>'
  );

  // Replace newline character `\n` with <br />
  useMarkdown = useMarkdown.replace(/\n/g, '<br />');

  return useMarkdown;
};

/**
 * Parses the response from the Airtable API and returns an array of record fields.
 *
 * @param {Object} response - The response object from the Airtable API.
 * @returns {Array} An array of record fields.
 */
const parseApiTableResponse = response => {
  if (!('records' in response)) return response;
  return response.records.map(record => record.fields);
};

export { airtableMarkdownToHtml, parseApiTableResponse };
