/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class AdContainer extends Component {
  constructor(props) {
    super(props);
    const { type } = this.props;
    const uid = Math.floor(Math.random() * 1e10);
    const adContainerId = this.props.adContainerId || `${type}_${uid}`;

    this.state = {
      adContainerId
    };
  }

  componentDidMount() {
    if (window && window._ash) {
      window._ash.push({
        type: 'onComponentDidMount',
        data: {
          props: this.props,
          state: this.state
        }
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    const { type } = nextProps;
    if (type === 'adhesive') {
      return true;
    }
    return false;
  }

  constructAdClass(type) {
    const classNameFull = `ad-wrapper ad-wrapper--${type}`;
    return classNameFull;
  }

  adLabel() {
    const slotsWithLabel = [
      'hero',
      'break',
      'rail',
      'rail_sticky',
      'in_content',
      'footer'
    ];

    const { type } = this.props;
    // Optionally display "advertisement" label
    if (slotsWithLabel.includes(type)) {
      return <div className="ad-wrapper__ad-slug">advertisement</div>;
    }
    return null;
  }

  render() {
    const { type, page = 1 } = this.props;

    const { adContainerId: id } = this.state;
    let wrapperClass = `ad-wrapper--ad-unit-wrap ${this.props.region || ''} ${
      this.props.hide ? 'hide' : 'show'
    }`.trim();

    const className = `adElement camp-${type} ${this.props.adClassName ||
      ''}`.trim();

    if (type.includes('native')) {
      wrapperClass = 'ad-unit-wrap-native';
    }
    return (
      <div className={wrapperClass}>
        {this.adLabel()}
        <div className={this.constructAdClass(type)}>
          <div
            id={id}
            className={className}
            data-ad_slot_type={type}
            data-page={page}
          />
        </div>
      </div>
    );
  }
}

AdContainer.propTypes = {
  adContainerId: PropTypes.string,
  adClassName: PropTypes.string,
  location: PropTypes.object,
  page: PropTypes.number,
  region: PropTypes.string,
  type: PropTypes.string,
  hide: PropTypes.bool
};

const mapStateToProps = state => ({
  location: state.routing.location
});

export default connect(mapStateToProps)(AdContainer);
