import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

export const Header = props => {
  if (!props.headers) return false;

  const handleClick = i => {
    const flip = props.column === i;

    props.sortData(i, flip);
    props.setColumn(i);
  };

  const handleMobileChange = target => {
    const { value, children } = target;

    children.forEach(child => {
      if (child.value === value) handleClick(child.index);
    });
  };

  if (!props.headers[props.tab].length > 0) return false;

  if (props.isMobile || props.isTablet) {
    return (
      <div className="header">
        <div className="container">
          <div>
            <span>Sort By</span>

            <select onChange={e => handleMobileChange(e.target)}>
              {props.headers[props.tab].map((header, i) => (
                <option key={`sort-${i}`}>{header.display}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="header">
      <div
        className={clsx('container', {
          hiring: props.enableHiringColumn
        })}
      >
        {props.headers[props.tab].map((header, i) => {
          const { name, display } = header;

          if (props.enableHiringColumn && name === 'hiring') {
            return <></>;
          }

          return (
            <div
              className={clsx(`column ${name}`, {
                active: props.column === i,
                inactive: props.column !== i,
                desc: props.column === i && props.sortDesc,
                asc: props.column !== i
              })}
              key={`column-${i}`}
              onClick={() => handleClick(i)}
            >
              {display}
              <span />
            </div>
          );
        })}
      </div>
    </div>
  );
};

Header.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  column: PropTypes.number.isRequired,
  sortData: PropTypes.func.isRequired,
  setColumn: PropTypes.func.isRequired,
  tab: PropTypes.number.isRequired,
  sortDesc: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  enableHiringColumn: PropTypes.bool,
  isTablet: PropTypes.bool
};

Header.defaultProps = {
  enableHiringColumn: false,
  isTablet: false
};
