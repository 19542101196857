import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import EventModel from '../../../../../utils/tracking/models';

export const Tab = props => {
  const handleTabClick = () => {
    const clickEvent = new EventModel(
      `BWI list tab-${get(props, 'category', 'no category')}`,
      'listTab',
      'click',
      `Tab-${get(props, 'category', 'no category')}`,
      `${props.tabIndex + 1}`
    );
    clickEvent.triggerTrackingEvent(
      'tab-click',
      clickEvent.getTrackingObject()
    );
  };

  return (
    <div
      className={`tab ${props.tab === props.tabIndex ? 'active' : 'inactive'}`}
      onClick={() => {
        props.setTab(props.tabIndex);
        handleTabClick();
      }}
    >
      {props.category && <div className="category">{props.category}</div>}
      {props.image && <img src={props.image} alt={props.category} />}
    </div>
  );
};

Tab.propTypes = {
  category: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  tab: PropTypes.number.isRequired,
  setTab: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired
};
