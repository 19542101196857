import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import {
  Image,
  OpenToggle,
  ShareButton
} from '../../../fc-components/main/components';
import Social from '../../../components/social/social';
import Pill from '../../../components/pill/Pill';
import get from 'lodash/get';
import EventModel from '../../../../utils/tracking/models';
import clsx from 'clsx';
import AdContainer from '../../ad/AdContainer';
import { airtableMarkdownToHtml } from '../../../../utils/airtable';

const AVAILABLE_BACKGROUND_COLORS = {
  0: '#f04b58',
  10: '#fff203',
  20: '#f8b6c0',
  30: '#00b3f0',
  40: '#f04b58',
  50: '#fff203',
  60: '#f8b6c0',
  70: '#00b3f0',
  80: '#f04b58',
  90: '#fff203'
};
const sections = {
  red: ['yellow', 'pale-blue', 'pink', 'light-blue'],
  yellow: ['pale-blue', 'pink', 'light-blue', 'red'],
  pink: ['yellow', 'pale-blue', 'light-blue', 'red'],
  blue: ['yellow', 'pale-blue', 'pink', 'red'],
  paleYellow: ['green', 'purple', 'pastel-blue'],
  green: ['pale-yellow', 'purple', 'pastel-blue'],
  purple: ['pale-yellow', 'green', 'pastel-blue'],
  pastelBlue: ['purple', 'pale-yellow', 'green']
};
const choosePillVariant = (index, year) => {
  let variant = '';
  if (year === '2024') {
    // switch out to switch case, this file might get migrated before we do
    if (
      index < 11 ||
      (index >= 40 && index < 50) ||
      (index >= 80 && index < 90)
    ) {
      variant =
        sections.paleYellow[
          Math.floor(Math.random() * sections.paleYellow.length)
        ];
    }
    if (
      (index >= 11 && index < 20) ||
      (index >= 50 && index < 60) ||
      (index >= 90 && index < 100)
    ) {
      variant =
        sections.green[Math.floor(Math.random() * sections.green.length)];
    }
    if ((index >= 20 && index < 30) || (index >= 60 && index < 70)) {
      variant =
        sections.purple[Math.floor(Math.random() * sections.purple.length)];
    }
    if ((index >= 30 && index < 40) || (index >= 70 && index < 80)) {
      variant =
        sections.pastelBlue[
          Math.floor(Math.random() * sections.pastelBlue.length)
        ];
    }
    return variant;
  }
  if (
    index < 11 ||
    (index >= 40 && index < 50) ||
    (index >= 80 && index < 90)
  ) {
    variant = sections.red[Math.floor(Math.random() * sections.red.length)];
  }
  if (
    (index >= 11 && index < 20) ||
    (index >= 50 && index < 60) ||
    (index >= 90 && index < 100)
  ) {
    variant =
      sections.yellow[Math.floor(Math.random() * sections.yellow.length)];
  }
  if ((index >= 20 && index < 30) || (index >= 60 && index < 70)) {
    variant = sections.pink[Math.floor(Math.random() * sections.pink.length)];
  }
  if ((index >= 30 && index < 40) || (index >= 70 && index < 80)) {
    variant = sections.blue[Math.floor(Math.random() * sections.blue.length)];
  }
  return variant;
};

export const BWIList = ({
  data,
  tab,
  isMobile,
  isTablet,
  iconStyle,
  year,
  params,
  enableShare,
  enableHiringColumn
}) => {
  const listData = data || null;
  const [active, setActive] = useState(
    parseInt(get(params, 'rank', null), 10) - 1
  );
  const [profileSocialOpen, setProfileSocialOpen] = useState(false);
  const [hiringPillColors, setHiringPillColors] = useState({});
  const [currentBackgroundColor, setCurrentBackgroundColor] = useState(0);

  if (!listData) return false;

  useEffect(
    () => {
      const rowToScroll = document.querySelector('.list-row.active');
      if (parseInt(get(params, 'rank', 0), 10) > 0 && rowToScroll) {
        rowToScroll.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    },
    [listData]
  );

  // set up hiring pill colors
  useState(() => {
    const colors = {};

    listData[0].data.forEach((_, index) => {
      colors[index] = choosePillVariant(index, year);
    });

    setHiringPillColors(colors);
  });

  const formatRank = rank => {
    if (rank >= 10 && rank < 100) {
      return `0${rank}`;
    } else if (rank < 10) {
      return `00${rank}`;
    }
    return rank;
  };

  const handleActive = (i, e) => { // eslint-disable-line
    if (active === i) {
      if (
        e.target.className === 'shareButton__label' ||
        e.target.nodeName === 'svg'
      ) {
        return '';
      }
      setActive(null);
    } else {
      setProfileSocialOpen(false);
      setActive(i);
    }
  };

  const toggleActiveItem = () => setActive(null);

  const handleListItemClick = (row, i) => {
    const clickEvent = new EventModel(
      `BWI Company-${get(row, 'company', 'no company')}`,
      'companyClick',
      'click',
      `nav-${get(row, 'company', 'no company')}`,
      `${i + 1}`
    );
    clickEvent.triggerTrackingEvent(
      'default-click',
      clickEvent.getTrackingObject()
    );
  };

  const handleListCompanyClick = (row, i) => {
    const clickEvent = new EventModel(
      `BWI Company-${get(row, 'title', 'no category')}`,
      'companyClick',
      'click',
      `nav-${get(row, 'title', 'no category')}`,
      `${i + 1}`
    );
    clickEvent.triggerTrackingEvent(
      'default-click',
      clickEvent.getTrackingObject()
    );
    window.open(row.url, '_blank');
  };

  switch (tab) {
    case 0:
      return (
        <div
          className={`list tab-${tab}`}
          style={{
            backgroundColor:
              AVAILABLE_BACKGROUND_COLORS[currentBackgroundColor],
            transition: 'background-color 250ms'
          }}
        >
          <Waypoint
            onEnter={() => setCurrentBackgroundColor(0)}
            topOffset="-50%"
          />
          {listData.map((row, i) => (
            <Fragment key={`fragment-holder-${i}`}>
              {i !== 0 &&
                Number.isInteger(i / 10) && (
                  <div className="list-row ad" key={`ad-${i / 10}`}>
                    <div className="container">
                      <AdContainer type="break" />
                    </div>
                    {year === '2023' && (
                      <Waypoint
                        onEnter={() => setCurrentBackgroundColor(i)}
                        topOffset="-50%"
                      />
                    )}
                  </div>
                )}
              <div
                className={`list-row ${active === i ? 'active' : ''}`}
                key={`row-${row.rank}`}
                onClick={e => {
                  handleActive(i, e);
                }}
              >
                <div
                  className={clsx('container', {
                    hiring: enableHiringColumn
                  })}
                >
                  <div className="column rank">{formatRank(row.rank)}</div>

                  <RowContent isMobile={isMobile}>
                    <h2 className="column company">{row.company}</h2>
                    {enableHiringColumn &&
                      !isMobile &&
                      isTablet && (
                        <div className="column hiring">
                          {row.hiring && (
                            <Pill
                              text="Hiring!"
                              variant={hiringPillColors[i]}
                              year={year}
                            />
                          )}
                        </div>
                      )}
                    <RowContentTablet isTablet={isTablet}>
                      {enableHiringColumn &&
                        !isTablet && (
                          <div className="column hiring">
                            {row.hiring && (
                              <Pill
                                text="Hiring!"
                                variant={hiringPillColors[i]}
                                year={year}
                              />
                            )}
                          </div>
                        )}
                      <div className="column location">{row.location}</div>
                      <div className="column industry">{row.industry}</div>
                    </RowContentTablet>
                  </RowContent>

                  <div
                    className="column icon"
                    onClick={() => toggleActiveItem()}
                  >
                    <OpenToggle active={active === i} />
                  </div>

                  {active === i && (
                    <div className="column description">
                      <div
                        className="copy"
                        dangerouslySetInnerHTML={{
                          __html: airtableMarkdownToHtml(row.description)
                        }}
                      />

                      <div className="descriptionWrapper">
                        {enableShare && (
                          <div className="share">
                            <ShareButton
                              label="Share"
                              onClick={() => {
                                setProfileSocialOpen(!profileSocialOpen);
                              }}
                              fill="black"
                              iconStyle={iconStyle}
                            />
                            {profileSocialOpen && (
                              <div className={clsx('social-box')}>
                                <Social
                                  modifier="StickyBar"
                                  disableStyle={true}
                                  hideRecirc={true}
                                  text={`${row.company} is number ${
                                    row.rank
                                  } on Fast Company's list of the Best Workplaces for Innovators in ${year}`}
                                  uri={`${encodeURI(window.location.href)}/${
                                    row.rank
                                  }`}
                                  horizontal
                                />
                              </div>
                            )}
                          </div>
                        )}

                        {row.url && (
                          <a
                            href={row.url}
                            className="button"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => {
                              handleListItemClick(row, i);
                            }}
                          >
                            Read More
                          </a>
                        )}

                        {row.jobLink && (
                          <a
                            href={row.jobLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => {
                              handleListItemClick(row, i);
                            }}
                            className="jobLink"
                          >
                            See Job Postings →
                          </a>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      );

    case 1:
      return (
        <div className={`list tab-${tab}`}>
          {listData.map((row, i) => (
            <div
              className="list-row"
              key={`row-${i}`}
              onClick={() => {
                handleListCompanyClick(row, i);
              }}
            >
              <div className="container">
                <div className="column icon">
                  <Image image={row.icon} width="60px" height="60px" />
                </div>

                <div className="column content">
                  <div className="title">{row.title}</div>
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{ __html: row.description }}
                  />
                </div>

                <div className="column link">
                  <Image
                    image={
                      year === '2022' || '2023'
                        ? 'https://images.fastcompany.net/image/upload/v1658775327/fcweb/__icon_-_blk_wd61f4.png'
                        : 'https://assets.fastcompany.com/image/upload/v1626644494/fcweb/icon_2x.webp'
                    }
                    width="30px"
                    height="30px"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      );

    default:
      return null;
  }
};

export const RowContent = ({ isMobile, children }) =>
  isMobile ? <div>{children}</div> : children;

export const RowContentTablet = ({ isTablet, children }) =>
  isTablet ? <div>{children}</div> : children;

BWIList.propTypes = {
  tab: PropTypes.number.isRequired,
  data: PropTypes.shape([]).isRequired
};

RowContent.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

RowContentTablet.propTypes = {
  isTablet: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};
